<template>
  <a-modal
    centered
    v-model="visible"
    :width="700"
    title="服务"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0, 'min-height': '400px' }"
    :ok-button-props="{ props: { disabled: !selectedData.id } }"
    @ok="handleOk"
    @cancel="selectedData = {}"
  >
    <a-card :bordered="false" :bodyStyle="{ padding: '16px 40px' }">
      <a-form-model class="ant-advanced-search-form">
        <a-col :lg="9" :md="9" :sm="24">
          <a-form-model-item label="输入搜索：">
            <a-input-search v-model="query.name" placeholder="请输入阶段名称" @search="initData" />
          </a-form-model-item>
        </a-col>
      </a-form-model>
      <a-table
        rowKey="id"
        :row-selection="{ type: 'radio', onSelect: onSelect }"
        :data-source="list"
        :pagination="pagination"
        :loading="list_loading"
        @change="handleTableChange"
      >
        <a-table-column key="name" title="名称" align="center" data-index="name" width="100px" />
        <a-table-column key="characteristic" title="介绍" align="center" data-index="characteristic" />
      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import * as serviceManagerApi from '@/api/serviceManager'
import { cloneDeep } from 'lodash'
export default {
  data() {
    return {
      query: {
        name: undefined
      },
      selectedData: {},
      list_loading: false,
      list: [],
      pagination: {}
    }
  },
  computed: {
    visible: {
      set: function(val) {
        this.$emit('update:show', val)
      },
      get: function() {
        return this.show
      }
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  activated() {
    if (this.ready) {
      this.initData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
    this.initData()
  },
  methods: {
    handleOk() {
      this.visible = false
      const { selectedData } = this
      this.$emit('confirm', cloneDeep(selectedData))
    },
    initData() {
      this.pagination = this.initPagination()

      this.getData()
    },
    getData() {
      this.list_loading = true
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this

      serviceManagerApi
        .list({ pageIndex, pageSize, ...query })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.list_loading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 4,
        showTotal: total => {
          const element = this.$createElement
          const {
            pagination: { pageSize }
          } = this
          return element('div', {}, [
            element('span', '共 '),
            element('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 4)),
            element('span', ' 页'),
            element('span', ' / '),
            element('span', { class: 'primary-color' }, total),
            element('span', ' 条数据')
          ])
        },
        showSizeChanger: false,
        total: 0,
        pageSizeOptions: ['4']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.getData()
    },
    onSelect(record) {
      const { id, type, characteristic, introduction, videoUrl } = record
      this.selectedData = { id, type, characteristic, introduction, videoUrl }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form {
  overflow: hidden;
  /deep/ .ant-form-item {
    display: flex;
    margin-bottom: 0;
  }
  /deep/ .ant-form-item-control-wrapper {
    flex: 1;
  }
}
</style>
