<template>
  <div>
    <a-table rowKey="id" :columns="columns" :data-source="specificationData">
      <template slot="originPrice" slot-scope="text, record">
        <div key="originPrice">
          <a-input-number
            v-model.trim="record.originPrice"
            :min="0"
            :max="999999.99"
            :precision="2"
            :formatter="value => `￥${value}`"
            :parser="value => value.replace('￥', '')"
            @blur="e => onBlur(e, record.id, 'originPrice')"
            @change="e => handleChange(e, record.id, 'originPrice')"
          />
        </div>
      </template>
      <template slot="discount" slot-scope="text, record">
        <div key="discount">
          <a-input-number
            v-model.trim="record.discount"
            :min="0"
            :max="100"
            :formatter="value => `${value}%`"
            :parser="value => value.replace('%', '')"
            @change="e => handleChange(e, record.id, 'discount')"
          />
        </div>
      </template>
      <template slot="sellPrice" slot-scope="text, record">
        <div key="sellPrice">
          <a-input-number
            disabled
            :value="(record.originPrice * record.discount) / 100"
            :min="0"
            :max="999999.99"
            :precision="2"
            :formatter="value => `￥${value}`"
            :parser="value => value.replace('￥', '')"
            @change="e => handleChange(e, record.id, 'sellPrice')"
          />
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <a-popconfirm title="确定要删除?" @confirm="() => onDelete(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { isNumber } from 'lodash'
const columns = [
  {
    title: '规格说明',
    dataIndex: 'description',
    width: '16%',
    align: 'center',
    scopedSlots: { customRender: 'description' }
  },
  {
    title: '值',
    dataIndex: 'value',
    width: '10%',
    align: 'center',
    scopedSlots: { customRender: 'value' }
  },
  {
    title: '实际价格',
    dataIndex: 'originPrice',
    width: '15%',
    align: 'center',
    scopedSlots: { customRender: 'originPrice' }
  },
  {
    title: '折扣',
    dataIndex: 'discount',
    width: '15%',
    align: 'center',
    scopedSlots: { customRender: 'discount' }
  },
  {
    title: '销售价格',
    dataIndex: 'sellPrice',
    width: '15%',
    align: 'center',
    scopedSlots: { customRender: 'sellPrice' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: '15%',
    align: 'center',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  data() {
    return {
      specificationData: [],
      columns
    }
  },
  watch: {
    specificationData: {
      handler: function(newVal) {
        this.onEmit()
      },
      deep: true
    }
  },
  methods: {
    initData(record) {
      const index = this.specificationData.length
      const { id = index.toString(), discount, description, value, originPrice, sellPrice } = record
      this.specificationData.push({
        id,
        description,
        value,
        discount,
        originPrice,
        sellPrice
      })
      this.onEmit()
    },
    onDelete(id) {
      const dataSource = [...this.specificationData]
      this.specificationData = dataSource.filter(item => item.id !== id)
    },
    onEmit() {
      const dataSource = [...this.specificationData]
      this.$emit('change', dataSource)
    },
    onBlur(e, id, column) {
      const newData = [...this.specificationData]
      const target = newData.filter(item => id === item.id)[0]
      if (!isNumber(target[column])) {
        target[column] = 0.01
        this.specificationData = newData
      }
    },
    handleChange(value, id, column) {
      const newData = [...this.specificationData]
      const target = newData.filter(item => id === item.id)[0]
      target.sellPrice = (target.originPrice * target.discount) / 100
      if (target) {
        target[column] = value
        this.specificationData = [...newData]
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
