var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form-model',_vm._b({ref:"form",attrs:{"model":_vm.form}},'a-form-model',_vm.$formItemLayout4DetailSm,false),[_c('a-form-model-item',{attrs:{"label":"名称：","prop":"goodsName","rules":{
        required: true,
        message: '请输入内容',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":25},model:{value:(_vm.form.goodsName),callback:function ($$v) {_vm.$set(_vm.form, "goodsName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.goodsName"}})],1),_c('a-form-model-item',{attrs:{"label":"封面：","prop":"coverUrl","rules":{
        required: true,
        message: '请上传封面',
        trigger: 'blur'
      }}},[_c('image-upload',{attrs:{"url":_vm.form.coverUrl},on:{"success":_vm.handleCoverUrl,"remove":function($event){_vm.form.coverUrl = ''}}})],1),_c('a-form-model-item',{attrs:{"label":"购买须知：","prop":"purchaseInstructions","rules":{
        required: true,
        message: '请输入内容',
        trigger: 'blur'
      }}},[_c('a-input',{attrs:{"placeholder":"请输入内容","maxLength":300,"autoSize":{ minRows: 3, maxRows: 3 },"type":"textarea"},on:{"change":() => _vm.$refs.form.clearValidate('instance')},model:{value:(_vm.form.purchaseInstructions),callback:function ($$v) {_vm.$set(_vm.form, "purchaseInstructions", $$v)},expression:"form.purchaseInstructions"}}),_c('div',{staticStyle:{"position":"absolute","bottom":"-10px","right":"2px"}},[_c('span',{staticStyle:{"background":"#fff"}},[_vm._v(_vm._s(_vm.form.purchaseInstructions.length)+"/300")])])],1),_c('a-form-model-item',{attrs:{"label":"是否在APP隐藏：","prop":"appHide","rules":{
        required: true,
        message: '请选择',
        trigger: 'blur'
      }}},[_c('a-radio-group',{model:{value:(_vm.form.appHide),callback:function ($$v) {_vm.$set(_vm.form, "appHide", $$v)},expression:"form.appHide"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否 ")])],1)],1),_c('a-form-model-item',{attrs:{"label":"服务选择","prop":"serviceId","rules":{
        required: true,
        message: '请选择'
      }}},[_c('a-button',{staticStyle:{"width":"100px"},attrs:{"type":"primary","shape":"round"},on:{"click":function($event){_vm.showServiceModel = true}}},[_vm._v("选择")]),_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-icon',{staticClass:"error-color",staticStyle:{"margin-right":"4px"},attrs:{"theme":"filled","type":"exclamation-circle"}}),_vm._v("关联服务都为必填项，请确保关联后保存。 ")],1)],1),(!_vm.form.serviceId)?[_c('a-col',{staticStyle:{"margin":"90px auto 90px auto"},attrs:{"type":"flex","align":"middle"}},[_c('a-row',[_c('a-avatar',{attrs:{"size":110,"shape":"square","src":"record.url"}})],1),_c('a-row',{staticStyle:{"margin-top":"20px"}},[_c('span',{staticClass:"add-tips"},[_vm._v("您还没添加任何服务")])])],1)]:[_c('a-form-model-item',{attrs:{"label":"分类："}},[_c('span',{staticClass:"service-label"},[_vm._v(_vm._s(_vm._f("ServiceManagerTypeText")(_vm.form.classification)))])]),_c('a-form-model-item',{attrs:{"label":"介绍："}},[_c('span',{staticClass:"service-label"},[_vm._v(_vm._s(_vm.serviceIntroduction))])]),_c('a-form-model-item',{attrs:{"label":"服务特色："}},[_c('span',{staticClass:"service-label"},[_vm._v(_vm._s(_vm.serviceCharacteristic))])]),_c('a-form-model-item',{attrs:{"label":"视频介绍："}},[_c('a',{staticClass:"service-label",attrs:{"href":_vm.serviceVideoUrl,"target":"blank_"}},[_c('a-icon',{staticStyle:{"margin-right":"8px"},attrs:{"type":"file"}}),_vm._v(_vm._s(_vm.serviceVideoUrl))],1)])],_c('a-form-model-item',{attrs:{"label":"是否创建规格：","prop":"hasSpecification","rules":{
        required: true,
        message: '',
        trigger: 'blur'
      }}},[_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-icon',{staticClass:"error-color",staticStyle:{"margin-right":"4px"},attrs:{"theme":"filled","type":"exclamation-circle"}}),_vm._v("商品售价为必填项，请确保填写完整后保存。 ")],1),_c('a-radio-group',{on:{"change":_vm.onSpecificationState},model:{value:(_vm.form.hasSpecification),callback:function ($$v) {_vm.$set(_vm.form, "hasSpecification", $$v)},expression:"form.hasSpecification"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v("是 ")]),_c('a-radio',{attrs:{"value":false}},[_vm._v("否 ")])],1)],1),(_vm.form.hasSpecification)?[_c('a-form-model-item',[_c('a-button',{staticStyle:{"margin-left":"60px","width":"100px"},attrs:{"type":"primary","shape":"round"},on:{"click":function($event){_vm.showSpecificationModal = true}}},[_vm._v("添加")])],1),_c('div',{staticStyle:{"padding":"20px 60px"}},[_c('SpecificationTable',{ref:"specification",on:{"change":_vm.onSpecificationChange}})],1)]:[_c('a-form-model-item',{attrs:{"label":"原始售价：","prop":"originPrice","rules":{
          required: true,
          message: '原始售价不能为空',
          trigger: 'blur'
        }}},[_c('a-input-number',{attrs:{"min":0,"max":999999.99,"precision":2,"formatter":value => `￥${value}`,"parser":value => value.replace('￥', '')},model:{value:(_vm.form.originPrice),callback:function ($$v) {_vm.$set(_vm.form, "originPrice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.originPrice"}})],1),_c('a-form-model-item',{attrs:{"label":"折扣：","prop":"discount","rules":{
          required: true,
          message: '折扣不能为空',
          trigger: 'blur'
        }}},[_c('a-input-number',{attrs:{"min":0,"max":100,"formatter":value => `${value}%`,"parser":value => value.replace('%', '')},model:{value:(_vm.form.discount),callback:function ($$v) {_vm.$set(_vm.form, "discount", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.discount"}})],1),_c('a-form-model-item',{attrs:{"label":"实际售价：","prop":"sellPrice","rules":{
          required: true,
          message: '实际售价不能为空',
          trigger: 'blur'
        }}},[_c('a-input-number',{attrs:{"disabled":"","min":0,"max":999999.99,"precision":2,"formatter":value => `￥${value}`,"parser":value => value.replace('￥', '')},model:{value:(_vm.form.sellPrice),callback:function ($$v) {_vm.$set(_vm.form, "sellPrice", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.sellPrice"}})],1)]],2),_c('ServiceModel',{attrs:{"show":_vm.showServiceModel},on:{"update:show":function($event){_vm.showServiceModel=$event},"confirm":_vm.onServiceConfirm}}),_c('SpecificationModal',{attrs:{"show":_vm.showSpecificationModal},on:{"update:show":function($event){_vm.showSpecificationModal=$event},"confirm":_vm.onSpecificationConfirm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }