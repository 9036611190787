<template>
  <div>
    <a-form-model ref="form" :model="form" v-bind="$formItemLayout4DetailSm">
      <a-form-model-item
        label="名称："
        prop="goodsName"
        :rules="{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }"
      >
        <a-input v-model.trim="form.goodsName" placeholder="请输入内容" :maxLength="25" />
      </a-form-model-item>
      <a-form-model-item
        label="封面："
        prop="coverUrl"
        :rules="{
          required: true,
          message: '请上传封面',
          trigger: 'blur'
        }"
      >
        <image-upload :url="form.coverUrl" @success="handleCoverUrl" @remove="form.coverUrl = ''"></image-upload>
      </a-form-model-item>
      <a-form-model-item
        label="购买须知："
        prop="purchaseInstructions"
        :rules="{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }"
      >
        <a-input
          v-model="form.purchaseInstructions"
          placeholder="请输入内容"
          :maxLength="300"
          :autoSize="{ minRows: 3, maxRows: 3 }"
          type="textarea"
          @change="() => $refs.form.clearValidate('instance')"
        />
        <div style="position:absolute;bottom:-10px;right:2px;">
          <span style="background:#fff">{{ form.purchaseInstructions.length }}/300</span>
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="是否在APP隐藏："
        prop="appHide"
        :rules="{
          required: true,
          message: '请选择',
          trigger: 'blur'
        }"
      >
        <a-radio-group v-model="form.appHide">
          <a-radio :value="true">是 </a-radio>
          <a-radio :value="false">否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item
        label="服务选择"
        prop="serviceId"
        :rules="{
          required: true,
          message: '请选择'
        }"
      >
        <a-button type="primary" shape="round" @click="showServiceModel = true" style="width:100px">选择</a-button>
        <div slot="extra">
          <a-icon
            style="margin-right:4px"
            class="error-color"
            theme="filled"
            type="exclamation-circle"
          />关联服务都为必填项，请确保关联后保存。
        </div>
      </a-form-model-item>
      <template v-if="!form.serviceId">
        <a-col style="margin:90px auto 90px auto" type="flex" align="middle">
          <a-row>
            <a-avatar :size="110" shape="square" src="record.url"></a-avatar>
          </a-row>
          <a-row style="margin-top:20px">
            <span class="add-tips">您还没添加任何服务</span>
          </a-row>
        </a-col>
      </template>
      <template v-else>
        <a-form-model-item label="分类：">
          <span class="service-label">{{ form.classification | ServiceManagerTypeText }}</span>
        </a-form-model-item>
        <a-form-model-item label="介绍："
          ><span class="service-label">{{ serviceIntroduction }}</span>
        </a-form-model-item>
        <a-form-model-item label="服务特色：">
          <span class="service-label">{{ serviceCharacteristic }}</span></a-form-model-item
        >
        <a-form-model-item label="视频介绍：">
          <a :href="serviceVideoUrl" target="blank_" class="service-label"
            ><a-icon type="file" style="margin-right:8px" />{{ serviceVideoUrl }}</a
          >
        </a-form-model-item>
      </template>
      <a-form-model-item
        label="是否创建规格："
        prop="hasSpecification"
        :rules="{
          required: true,
          message: '',
          trigger: 'blur'
        }"
      >
        <div slot="extra">
          <a-icon
            style="margin-right:4px"
            class="error-color"
            theme="filled"
            type="exclamation-circle"
          />商品售价为必填项，请确保填写完整后保存。
        </div>
        <a-radio-group v-model="form.hasSpecification" @change="onSpecificationState">
          <a-radio :value="true">是 </a-radio>
          <a-radio :value="false">否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <template v-if="form.hasSpecification">
        <a-form-model-item>
          <a-button
            type="primary"
            shape="round"
            @click="showSpecificationModal = true"
            style="margin-left:60px;width:100px"
            >添加</a-button
          >
        </a-form-model-item>
        <div style="padding:20px 60px">
          <SpecificationTable ref="specification" @change="onSpecificationChange" />
        </div>
      </template>
      <template v-else>
        <a-form-model-item
          label="原始售价："
          prop="originPrice"
          :rules="{
            required: true,
            message: '原始售价不能为空',
            trigger: 'blur'
          }"
        >
          <a-input-number
            v-model.trim="form.originPrice"
            :min="0"
            :max="999999.99"
            :precision="2"
            :formatter="value => `￥${value}`"
            :parser="value => value.replace('￥', '')"
          />
        </a-form-model-item>
        <a-form-model-item
          label="折扣："
          prop="discount"
          :rules="{
            required: true,
            message: '折扣不能为空',
            trigger: 'blur'
          }"
        >
          <a-input-number
            v-model.trim="form.discount"
            :min="0"
            :max="100"
            :formatter="value => `${value}%`"
            :parser="value => value.replace('%', '')"
          />
        </a-form-model-item>
        <a-form-model-item
          label="实际售价："
          prop="sellPrice"
          :rules="{
            required: true,
            message: '实际售价不能为空',
            trigger: 'blur'
          }"
        >
          <a-input-number
            v-model.trim="form.sellPrice"
            disabled
            :min="0"
            :max="999999.99"
            :precision="2"
            :formatter="value => `￥${value}`"
            :parser="value => value.replace('￥', '')"
          />
        </a-form-model-item>
      </template>
    </a-form-model>
    <ServiceModel :show.sync="showServiceModel" @confirm="onServiceConfirm" />
    <SpecificationModal :show.sync="showSpecificationModal" @confirm="onSpecificationConfirm" />
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import ServiceModel from './ServiceModel'
import SpecificationModal from './SpecificationModal'
import SpecificationTable from './SpecificationTable'
import { ImageUpload } from '@/components/_heath'
import * as goodsserviceApi from '@/api/goodsservice'
export default {
  components: {
    ImageUpload,
    ServiceModel,
    SpecificationModal,
    SpecificationTable
  },
  data() {
    return {
      showServiceModel: false,
      showSpecificationModal: false,
      form: {
        appHide: false,
        classification: '',
        coverUrl: '',
        discount: 100,
        hasSpecification: false,
        individualSpecificationsSet: [],
        goodsName: '',
        originPrice: '',
        purchaseInstructions: '',
        sellPrice: '',
        serviceId: ''
      },
      serviceName: '',
      serviceCharacteristic: '',
      serviceIntroduction: '',
      serviceVideoUrl: ''
    }
  },
  computed: {
    priceRange() {
      const {
        form: { originPrice = 0, discount = 0 }
      } = this
      return {
        originPrice,
        discount
      }
    }
  },
  watch: {
    priceRange(val) {
      this.form.sellPrice = (val.originPrice * val.discount) / 100
    }
  },
  methods: {
    initData(id) {
      if (id) {
        goodsserviceApi.individualDetail(id).then(res => {
          const {
            appHide,
            coverUrl,
            discount,
            hasSpecification,
            goodsName,
            originPrice,
            purchaseInstructions,
            sellPrice,
            goodsSpecificationsVOSet: individualSpecificationsSet,
            service
          } = res.data

          const {
            characteristic,
            name: serviceName,
            id: serviceId,
            type: classification,
            introduction,
            videoUrl
          } = service
          this.form = {
            id,
            appHide,
            classification,
            coverUrl,
            discount,
            hasSpecification,
            goodsName,
            originPrice,
            purchaseInstructions,
            sellPrice,
            serviceId,
            individualSpecificationsSet
          }
          this.serviceName = serviceName
          this.serviceCharacteristic = characteristic
          this.serviceIntroduction = introduction
          this.serviceVideoUrl = videoUrl
          if (hasSpecification) {
            this.$nextTick(() => {
              individualSpecificationsSet.forEach(element => {
                this.initSpecificationData(element)
              })
            })
          }
        })
      }
    },
    validate() {
      const {
        $refs: { form },
        form: { hasSpecification, individualSpecificationsSet }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (hasSpecification && individualSpecificationsSet.length < 1) {
            this.$message.info('请添加商品规格')
            return reject(err)
          }
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        return { ...ret }
      } catch {}
    },
    handleCoverUrl(url) {
      this.form.coverUrl = url
      this.$refs.form.clearValidate('coverUrl')
    },
    onServiceConfirm(record) {
      const { id: serviceId, type: classification, characteristic, introduction, videoUrl } = record
      this.$set(this.form, 'classification', classification)
      this.$set(this.form, 'serviceId', serviceId)
      this.serviceIntroduction = introduction
      this.serviceCharacteristic = characteristic
      this.serviceVideoUrl = videoUrl
    },
    onSpecificationConfirm(record) {
      this.initSpecificationData({ discount: 100, originPrice: '', sellPrice: '', ...record })
    },
    initSpecificationData(record) {
      this.$refs.specification.initData(record)
    },
    onSpecificationChange(record) {
      const {
        form: { goodsName }
      } = this
      record.forEach(item => {
        item.goodsName = goodsName
      })
      this.$set(this.form, 'individualSpecificationsSet', [...record])
    },
    onSpecificationState() {
      this.$set(this.form, 'individualSpecificationsSet', [])
      this.form.originPrice = ''
      this.form.discount = 100
      this.form.sellPrice = ''
    }
  }
}
</script>

<style lang="less" scoped>
.service-label {
  white-space: normal;
  word-break: break-all;
}

/deep/ .avatar-uploader-wrap {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-wrap {
  width: 100px;
  height: 100px;
}
</style>
