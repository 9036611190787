<template>
  <a-modal
    centered
    v-model="visible"
    :width="400"
    title="服务"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    @ok="handleOk"
    @cancel="clear"
  >
    <a-card :bordered="false" :bodyStyle="{ padding: '16px 40px' }">
      <a-form-model ref="form" :bordered="false" :model="form" :labelCol="{ span: 8 }" :wrapperCol="{ span: 14 }">
        <a-form-model-item
          label="规格说明："
          prop="description"
          :rules="{
            required: true,
            message: '请输入规格说明',
            trigger: 'blur'
          }"
        >
          <a-input v-model.trim="form.description" :maxLength="25" />
        </a-form-model-item>
        <a-form-model-item
          label="值："
          prop="value"
          :rules="{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }"
        >
          <a-input-number v-model.trim="form.value" :min="0" :max="100" />
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </a-modal>
</template>

<script>
import { cloneDeep } from 'lodash'
export default {
  data() {
    return {
      form: {
        description: undefined,
        value: undefined
      }
    }
  },
  computed: {
    visible: {
      set: function(val) {
        this.$emit('update:show', val)
      },
      get: function() {
        return this.show
      }
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    validate() {
      const {
        $refs: { form }
      } = this
      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    handleOk() {
      this.$refs.form.validate().then(valid => {
        if (valid) {
          this.visible = false
          const { form } = this
          this.$emit('confirm', cloneDeep(form))
          this.clear()
        }
      })
    },
    clear() {
      this.form = { description: undefined, value: undefined }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-input-number {
  width: 100%;
}
</style>
