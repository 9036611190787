var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"centered":"","width":400,"title":"服务","destroyOnClose":true,"bodyStyle":{ padding: 0 }},on:{"ok":_vm.handleOk,"cancel":_vm.clear},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-card',{attrs:{"bordered":false,"bodyStyle":{ padding: '16px 40px' }}},[_c('a-form-model',{ref:"form",attrs:{"bordered":false,"model":_vm.form,"labelCol":{ span: 8 },"wrapperCol":{ span: 14 }}},[_c('a-form-model-item',{attrs:{"label":"规格说明：","prop":"description","rules":{
          required: true,
          message: '请输入规格说明',
          trigger: 'blur'
        }}},[_c('a-input',{attrs:{"maxLength":25},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.description"}})],1),_c('a-form-model-item',{attrs:{"label":"值：","prop":"value","rules":{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }}},[_c('a-input-number',{attrs:{"min":0,"max":100},model:{value:(_vm.form.value),callback:function ($$v) {_vm.$set(_vm.form, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.value"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }